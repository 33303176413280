<template>
  <div class="menuTree" :class="['menuTree', `menuTree_${level}`]">
    <template v-for="(item, index) in menuList">
      <el-submenu
        v-if="item.children && item.children.length > 0"
        :key="index"
        :index="item.id"
        :class="[
          `level_${level}`,
          {
            isEcho: showPathArr.includes(item.id),
          },
        ]"
      >
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <img
            :src="item.source.split('|')[0]"
            v-if="level === 1"
            v-show="!showPathArr.includes(item.id)"
            alt=""
          />
          <img
            :src="item.source && item.source.split('|')[1] ? item.source.split('|')[1] : item.source.replace('.png', '-light.png')"
            v-if="level === 1"
            v-show="showPathArr.includes(item.id)"
            alt=""
          />
          <span>{{ item.name }}</span>
        </template>
        <menuTree
          :menuList="item.children"
          :level="level + 1"
          :showPathArr="showPathArr"
        />
      </el-submenu>
      <el-menu-item
        :class="{ isEcho: showPathArr.includes(item.id) }"
        v-else
        :index="item.id"
        :key="item.id"
        @click="toLink(item)"
      >
        <!-- <i class="el-icon-caret-right" style="opacity: 0"></i> -->
        <span class="contentTitle" slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "menuTree",
  props: {
    // 组件当前层级
    level: {
      type: [Number, String],
      default: 1,
    },
    // 需要加上样式的节点唯一id集合
    showPathArr: {
      type: Array,
      default() {
        return [];
      },
    },
    // 当前项数据
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isCollapse: false,
      // idType: ["level1Id", "level2Id", "level3Id"],
    };
  },
  methods: {
    toLink(item) {
      if (item.alias == "third-backend") {
        this.$router.push({
          name: "oIframe",
          query: {
            path: item.path,
            modelKey: item.modelKey,
            fromId: item.id,
          },
        });
      } else {
        if (item.url) {
          this.$router.push({
            name: item.url,
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.menuTree {
  padding-left: 15px;
  &.menuTree_1 {
    padding-left: 0;
  }
  .level_1 {
    ::v-deep > .el-submenu__title {
      padding: 0 0 0 40px !important;
      position: relative;
      > img {
        position: absolute;
        left: 10px;
        top: 18px;
        width: 20px;
        height: 20px;
      }
      &:hover {
        color: #409eff;
        background: #fff !important;
      }
    }
  }
  ::v-deep .el-submenu__title {
    padding: 0 0 0 30px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // border-radius: 4px;
    &:hover {
      color: #409eff;
      background-color: transparent;
    }
    i.el-submenu__icon-arrow {
      // display: none;
    }
  }
  ::v-deep .el-menu-item {
    width: 100%;
    min-width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0 0 30px !important;
    &:hover {
      color: #409eff;
      background: transparent;
    }
    &:focus {
      background: transparent;
    }
    &.is-active {
      color: #303133;
    }
  }

  .isEcho {
    ::v-deep > .el-submenu__title {
      color: #409eff;
      font-weight: bold;
    }
  }
  .isEcho.level_1 {
    ::v-deep > .el-submenu__title {
      background-color: #fff;
    }
  }
  ::v-deep .el-menu-item {
    &.isEcho {
      color: #409eff;
      background: #f7f8fb;
      border-radius: 20px 0 0 20px;
      .contentTitle {
        position: relative;
        &::before {
          position: absolute;
          left: -15px;
          top: 5px;
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          background: #409eff;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
