<template>
  <div class="container">
    <div class="container-top">
      <div class="logo">
        <!-- <div class="logo_icon"><img src="@/assets/logo.png" alt="" /></div> -->
        <div class="logo_txt">宁波市级未来社区数字化看板</div>
      </div>
      <!-- <div class="section" @click="goback()">
        <div class="section_txt back">返回</div>
        <img src="@/assets/goback.png" alt="" />
      </div>
      <div class="line"></div>

      <div class="section" @click="unfinished()">
        <img src="@/assets/videoIcon.png" alt="" />
        <div class="section_txt">教学视频</div>
      </div>
      <div class="line"></div>

      <div class="section" @click="download()">
        <img src="@/assets/manualIcon.png" alt="" />
        <div class="section_txt">使用手册</div>
      </div>
      <div class="line"></div> -->

      <div class="avatar">
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <img src="@/assets/img/user.png" class="user-avatar" />
            <span>{{ username }}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>用户名：{{ username }}</el-dropdown-item>
            <el-dropdown-item divided @click.native="changePassWord()">
              <span style="display: block">修改密码</span>
            </el-dropdown-item>

            <el-dropdown-item divided @click.native="logout">
              <span style="display: block">退出</span>
              <!-- <i class="el-icon-arrow-right"></i> -->
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div :class="['container-bottom', classObj]">
      <div class="nav-side">
        <sideBar />
      </div>
      <div class="main-container">
        <!-- 修改密码弹框 -->
        <v-dialog
          :closeOnClickModal="false"
          title="修改密码"
          v-model="dialogFormVisible"
          width="300px"
          @close="closeDialog"
          :showCancelButton="false"
          sureTxt="保存"
          @confirm="submit"
          oStyle="justify-content: center;"
        >
          <div class="tips" v-if="isDate">* {{ tips }}</div>
          <el-form ref="loginForm" :rules="modifyRules">
            <el-form-item>
              <v-input
                type="password"
                v-model="oldPassword"
                :width="width"
                placeholder="当前密码"
              />
            </el-form-item>
            <el-form-item prop="newPassword">
              <v-input
                type="password"
                v-model="newPassword"
                :width="width"
                placeholder="新密码"
              />
            </el-form-item>
            <el-form-item prop="newPasswordRe">
              <v-input
                type="password"
                v-model="newPasswordRe"
                :width="width"
                placeholder="确认新密码"
              />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer dialogBottomBtn">
            <template v-if="isDate">
              <v-button text="下回修改" @click="clickCloseDialog"></v-button>
              <v-button text="确认修改" @click="submit"></v-button>
            </template>
            <template v-else>
              <v-button size="medium" text="保存" @click="submit()"></v-button>
            </template>
          </div>
        </v-dialog>
        <div class="main-container-t">
          <Hamburger
            id="hamburger-container"
            :is-active="sidebar.opened"
            class="hamburger-container"
            @toggleClick="toggleSideBar"
          /><Breadcrumb />
        </div>
        <div class="main-container-b" ref="main">
          <transition name="fade-transform" mode="out-in">
            <div class="main-container-b-body">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive" />
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive" />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { changePassWord, timeCheckURL } from "./api.js";
import { mapState } from "vuex";
import { _localStorage } from "@/utils/utils";
import Hamburger from "@/components/bussiness/Hamburger";
import sideBar from "@/components/bussiness/nav/sideBar.vue";
import Breadcrumb from "@/components/bussiness/Breadcrumb";
import { downloadHelper } from "@/utils/func";
import { passWordRegular } from "@/utils/regular";
export default {
  name: "home",
  components: {
    sideBar,
    Hamburger,
    Breadcrumb,
  },
  data() {
    let validateNewPassword = (rule, value, callback) => {
      console.log(this.newPassword, value, "--");
      if (
        !this.newPassword.match(
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
        )
      ) {
        callback(new Error("需要至少8位包含英文、字符、数字的密码"));
      } else {
        callback();
      }
    };
    let validateNewPasswordRepeat = (rule, value, callback) => {
      console.log(value, this.newPassword, "--");
      if (this.newPasswordRe != this.newPassword) {
        callback(new Error("两次密码必须一致"));
      } else {
        callback();
      }
    };
    let _this = this;
    return {
      width: 260,
      modifyRules: {
        newPassword: [
          { required: true, trigger: "blur", validator: validateNewPassword },
        ],
        newPasswordRe: [
          {
            required: true,
            trigger: "blur",
            validator: validateNewPasswordRepeat,
          },
        ],
      },
      isCollapse: true,
      username: "",
      logoName: "",
      dialogFormVisible: false,
      oldPassword: "",
      newPassword: "",
      newPasswordRe: "",
      isDate: false, // 是否密码修改日期超过90天
      tips: "",
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
    ...mapState("app", ["sidebar", "userInfo"]),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
      };
    },
  },
  created() {
    this.logoName = sessionStorage.getItem("logoName");
    let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
    this.$store.commit("app/setUserInfo", userInfo);
    this.vaildChangePasswordDate();
  },
  mounted() {
    this.username = this.userInfo.userName;
    /** 后期通过接口获取用户信息，现阶段直接使用sessionStorage */
    // const userInfo = sessionStorage.getItem("userInfo");
    // this.username = JSON.parse(userInfo).userName;
  },
  updated() {
    this.pathFun();
  },

  methods: {
    async vaildChangePasswordDate() {
      let onOff = sessionStorage.getItem("nextModify_password");
      if (onOff) {
        return;
      }
      let res = await this.$axios.get(timeCheckURL, {
        params: {
          userId: this.userInfo.userId,
        },
      });
      let result = undefined;
      if (res.code == 200 && res.success) {
        result = res.data;
      }
      // const changePasswordTime = this.userInfo.changePasswordTime;
      // let result =
      //   changePasswordTime != ""
      //     ? moment(changePasswordTime).add(90, "d").isAfter(new Date())
      //     : false;
      // if (!result) {
      if (result) {
        this.tips =
          "您近90天未修改登录密码，为了您的账号安全，请定时修改密码！";
        // this.$message.warning(
        //   "您近90天未修改登录密码，为了您的账号安全，请定时修改密码！"
        // );
        this.isDate = true;
        this.dialogFormVisible = true;
        // this.changePassWord();
      }
    },
    pathFun() {
      if (String(this.$route.path) == "/home/iotInternet/overView") {
        let that = this;
        that.$refs.main.style.background = "transparent";
      } else {
        let that = this;
        that.$refs.main.style.background = "#fff";
      }
    },
    closeDialog() {
      if (this.isDate) {
        sessionStorage.setItem("nextModify_password", "nextModify_password");
      }
      this.oldPassword = "";
      this.newPassword = "";
      this.newPasswordRe = "";
    },
    clickCloseDialog() {
      this.dialogFormVisible = false;
    },
    changePassWord() {
      this.tips = "";
      this.isDate = false;
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        if (this.$refs.loginForm) {
          this.$refs.loginForm.clearValidate();
        }
      });
    },
    async submit() {
      if (this.oldPassword == "") {
        this.$message.error("当前密码不能为空！");
        return;
      }
      if (this.newPassword == "") {
        this.$message.error("新密码不能为空！");
        return;
      }
      if (this.newPasswordRe == "") {
        this.$message.error("请确认密码！");
        return;
      }
      if (
        !this.newPassword.match(
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
        )
      ) {
        this.$message.error("请输入正确的密码格式！");
        return;
      }
      if (this.newPassword != this.newPasswordRe) {
        this.$message.error("两次输入密码不一致！");
        return;
      }
      if (this.oldPassword == this.newPassword) {
        this.$message.error("请修改不同密码！");
        return;
      }
      let params = {
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        newPasswordRe: this.newPasswordRe,
      };
      let res = await this.$axios.get(`${changePassWord}`, { params: params });
      if (res.response) {
        this.$message.error(res.response.data.msg);
      }
      if (res.code === 200) {
        this.oldPassword = "";
        this.newPassword = "";
        this.newPasswordRe = "";
        this.dialogFormVisible = false;
        this.$message.success("密码修改成功，请重新登录！");
        setTimeout(() => {
          sessionStorage.clear(); // 清空存储
          this.$router.replace({
            name: "login",
          });
        }, 1000);
      }
    },

    goback() {
      this.$router.replace({
        name: "guide",
      });
    },
    download() {
      // https://gxq.zhuneng.cn:444/imgForManager/static/index/明珠社区运营管理平台用户使用手册.pdf

      // let config = {
      //   src: "https://gxq.zhuneng.cn:444/imgForManager/static/index/明珠社区运营管理平台用户使用手册.pdf",
      //   rel: "noopener noreferrer",
      //   target: "_blank",
      // };
      // config.fileName = "明珠社区运营管理平台用户使用手册";
      // downloadHelper.downloadBySrc(config);
      this.$message({
        type: "warning",
        message: "敬请期待！",
        showClose: true,
      });
    },
    unfinished() {
      this.$message({
        type: "warning",
        message: "敬请期待！",
        showClose: true,
      });
    },
    logout() {
      this.$router.replace({
        name: "login",
      });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/less/global.less";
@import "~@/assets/less/sideBar.less";
.container {
  padding-top: @topHeight;
  box-sizing: border-box;
  height: 100%;

  .container-top {
    height: @topHeight;
    margin-top: -@topHeight;
    background: #fff;
    box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    padding: 0 52px 0 28px;
    box-sizing: border-box;
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
      .logo_icon {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logo_txt {
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        font-size: 28px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 40px;
        text-align: left;
        margin-left: 15px;
      }
    }
    .section {
      cursor: pointer;
      align-items: center;
      display: flex;
      height: 100%;
      .section_txt {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
        line-height: 22px;
        &.back {
          color: rgba(47, 124, 255, 1);
        }
      }
      > img {
        width: 20px;
        height: 20px;
        margin: 0 8px;
      }
    }
    .line {
      height: 100%;
      position: relative;
      padding: 0 20px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 22px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .avatar {
      cursor: pointer;
      .avatar-container {
        .avatar-wrapper {
          display: flex;
          align-items: center;
          .user-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          span {
            margin: 0 8px 0 8px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            font-family: PingFangSC-Regular;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
          }

          .el-icon-caret-bottom {
            font-size: 16px;
          }
        }
      }
      // .right-menu-item {
      //   display: inline-block;
      //   padding: 0 8px 0 0;
      //   height: 100%;
      //   font-size: 18px;
      //   color: #5a5e66;
      //   vertical-align: text-bottom;

      //   &.hover-effect {
      //     cursor: pointer;
      //     transition: background 0.3s;

      //     &:hover {
      //       background: rgba(0, 0, 0, 0.025);
      //     }
      //   }
      // }
    }
  }
  .container-bottom {
    height: 100%;
    position: relative;
    .nav-side {
      transition: width 0.28s;
      width: @sideBarWidth;
      // background-color: @menuBg;
      box-shadow: 0px 0px 40px -3px rgba(0, 0, 0, 10%) inset;
      background-color: #f0f3f7;
      height: 100%;
      position: absolute;
      font-size: 0px;
      top: 0;
      left: 0;
      z-index: 1001;
      // overflow: scroll;
      overflow: hidden;
      box-sizing: border-box;
    }
    .main-container {
      box-sizing: border-box;
      height: 100%;
      overflow: hidden;
      transition: margin-left 0.28s;
      margin-left: @sideBarWidth;
      position: relative;
      background: #f7f8fbff;
      padding: 24px 24px;
      .tips {
        color: #f56c6c;
        font-size: 14px;
        margin-bottom: 12px;
      }
      .main-container-t {
        position: absolute;
        height: 50px;
        display: flex;
        align-items: center;
        z-index: 1;
      }
      // .main-container-b {
      //   position: relative;
      //   padding-top: 50px;
      // }
      .main-container-b {
        height: 100%;
        box-sizing: border-box;
        background: #fff;
        .main-container-b-body {
          height: 100%;
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
    }
    /** 点击收缩时 */
    &.hideSidebar {
      .nav-side {
        width: 64px;
        overflow: hidden;
        ::v-deep .el-submenu__title {
          span {
            display: none;
          }
          i.el-submenu__icon-arrow {
            display: none;
          }
        }
      }
      .main-container {
        margin-left: 64px;
      }
    }
    // .main-container-b {
    //   height: 100%;
    //   box-sizing: border-box;
    // }
    // .main-container-b-body {
    //   height: 100%;
    //   box-sizing: border-box;
    //   padding: 0 20px;
    // }
  }
}
</style>
