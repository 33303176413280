<template>
  <div class="hc-sideBar">
    <el-scrollbar class="scrollbar">
      <el-menu
        ref="elMenu"
        :collapse="!sidebar.opened"
        :default-openeds="defaultOpeneds"
      >
        <menuTree
          ref="menuTree"
          :menuList="menuList"
          :showPathArr="showPathArr"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import menuTree from "@/components/bussiness/menuTree/index.vue";

export default {
  name: "sideBar",
  components: { menuTree },
  data() {
    return {
      isCollapse: false,
      showPathArr: [],
      oList: [],
      levelList: [],
    };
  },
  computed: {
    defaultOpeneds() {
      return [...this.showPathArr];
    },
    ...mapState("app", [
      "sidebar",
      "menuList",
      "formatMenu",
      "flatMenu",
      "menuLimit",
    ]),
  },
  watch: {
    $route: {
      handler(val) {
        if (this.$route.meta.fromId == "第三方") {
          this.handleTeRouter();
        } else {
          this.handleRouter();
        }
        if (val.path.startsWith("/redirect/")) {
          return;
        }
        if (this.$route.meta.fromId == "第三方") {
          this.getTeBreadcrumb();
        } else {
          this.getBreadcrumb();
        }

        // const sideBarStr = sessionStorage.getItem("sideBar");
        // const sideBar = sideBarStr ? JSON.parse(sideBarStr) : {};
        // if (this.showPathArr.length === 0) {
        //   this.showPathArr = sideBar.pathKey || [];
        // }
        //  else if (this.showPathArr.length !== 0) {
        //   sessionStorage.setItem(
        //     "sideBar",
        //     JSON.stringify({
        //       ...sideBar,
        //       pathKey: this.showPathArr,
        //     })
        //   );
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit("app/setMenu", this.oList);
    this.getMenu();
  },
  beforeDestroy() {
    sessionStorage.removeItem("sideBar");
  },
  methods: {
    getTeBreadcrumb() {
      let fromId = this.$route.query.fromId;
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
      } else {
        // 如果meta.echo不存在，说明不是次级页面
        this.$clearBreadList();
      }

      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.id === fromId) {
          result = [menu, ...menu.parentArr]
            .reverse()
            .map((v) => ({ title: v.name, id: v.id }));
        }
      });

      const breadcrumbStr = sessionStorage.getItem("breadcrumb");
      const breadcrumb = breadcrumbStr ? JSON.parse(breadcrumbStr) : {};
      if (result.length > 0) {
        this.levelList = result;
        this.$store.commit("app/setLevelList", this.levelList);
      }
    },
    getBreadcrumb() {
      let curPath = this.$route.path;
      console.log(this.$route.meta, "this.$route");
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
        curPath = this.$route.meta.echo;
      } else {
        // 如果meta.echo不存在，说明不是次级页面
        this.$clearBreadList();
      }
      // 路由以 /home 为前缀
      if (/^\/home/.test(curPath)) {
        curPath = curPath.replace(/^\/home\//, "");
      } else {
        curPath = curPath.replace(/^\//, "");
      }
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.path === curPath) {
          result = [menu, ...menu.parentArr]
            .reverse()
            .map((v) => ({ title: v.name, id: v.id }));
        }
      });
      console.log(result, "result---");
      const breadcrumbStr = sessionStorage.getItem("breadcrumb");
      const breadcrumb = breadcrumbStr ? JSON.parse(breadcrumbStr) : {};
      if (result.length > 0) {
        // sessionStorage.setItem(
        //   "breadcrumb",
        //   JSON.stringify({
        //     old: breadcrumb.new || [],
        //     new: result,
        //   })
        // );
        this.levelList = result;
        this.$store.commit("app/setLevelList", this.levelList);
      }
    },
    handleTeRouter() {
      let fromId = this.$route.query.fromId;
      const flatMenu = this.flatMenu;
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.id === fromId) {
          result = [menu, ...menu.parentArr].reverse();
        }
      });
      if (result.length > 0) {
        this.showPathArr = result.map((v) => v.id);
      }
    },
    handleRouter() {
      let curPath = this.$route.path;
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
        curPath = this.$route.meta.echo;
      }
      if (/^\/home/.test(curPath)) {
        curPath = curPath.replace(/^\/home\//, "");
      } else {
        curPath = curPath.replace(/^\//, "");
      }
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.path === curPath) {
          result = [menu, ...menu.parentArr].reverse();
        }
      });
      if (result.length > 0) {
        this.showPathArr = result.map((v) => v.id);
      }
    },
    getMenu() {
      let params = {
        roleId: this.$store.state.app.userInfo.roleId,
        userId: this.$store.state.app.userInfo.userId,
      };
      let menuLimit = JSON.parse(sessionStorage.getItem("menuLimit"));
      this.$store.commit("app/setMenuLimit", menuLimit);
      let url = `/gateway/blade-system/menu/routes`;
      if (this.menuLimit.name && this.menuLimit.type) {
        url = `/gateway/blade-system/menu/routes?domain=${this.menuLimit.type}`;
      }
      this.$axios.post(url, params).then((res) => {
        if (res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          res.data.forEach(item => {
            if (item.url === 'provincePlatformDataUpReport') {
              item.children = [
                {
                  id: "3123598815738675343",
                  parentId: "3123598815738675342",
                  code: "baseDataReport",
                  name: "基础数据上报",
                  alias: "menu",
                  path: "",
                  url: "",
                  source: "iconfont iconicon_sms",
                  sort: 1,
                  category: 1,
                  action: 0,
                  isOpen: 1,
                  remark: "",
                  isDeleted: 0,
                  tenantId: "00000",
                  currentPath: "",
                  redirect: "",
                  hidden: false,
                  domain: 0,
                  modelKey: "",
                  parentName: "",
                  categoryName: "",
                  actionName: "",
                  isOpenName: "",
                  hasChildren: false,
                  children: [
                    {
                      id: "3000598815738675000",
                      parentId: "3123598815738675343",
                      code: "baseDataCommunity",
                      name: "社区主体库",
                      alias: "menu",
                      path: "",
                      url: "",
                      source: "iconfont iconicon_sms",
                      sort: 1,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      remark: "",
                      isDeleted: 0,
                      tenantId: "00000",
                      currentPath: "",
                      redirect: "",
                      hidden: false,
                      domain: 0,
                      modelKey: "",
                      parentName: "",
                      categoryName: "",
                      actionName: "",
                      isOpenName: "",
                      hasChildren: false,
                      children: [
                        {
                          id: "3000598815738674000",
                          parentId: "3000598815738675000",
                          code: "reportPartyOrg",
                          name: "上报党组织信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportPartyOrg",
                          url: "reportPartyOrg",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674001",
                          parentId: "3000598815738675000",
                          code: "reportPartyMemberInfo",
                          name: "上报党员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportPartyMemberInfo",
                          url: "reportPartyMemberInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674002",
                          parentId: "3000598815738675000",
                          code: "reportGroupOrg",
                          name: "上报群团组织信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportGroupOrg",
                          url: "reportGroupOrg",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674007",
                          parentId: "3000598815738675000",
                          code: "reportGroupPerson",
                          name: "上报群团成员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportGroupPerson",
                          url: "reportGroupPerson",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },

                        {
                          id: "3000598815738674004",
                          parentId: "3000598815738675000",
                          code: "reportPropertyInfo",
                          name: "上报物业信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportPropertyInfo",
                          url: "reportPropertyInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674015",
                          parentId: "3000598815738675000",
                          code: "propertyComplexRelation",
                          name: "上报物业与小区关系信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/propertyComplexRelation",
                          url: "propertyComplexRelation",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674014",
                          parentId: "3000598815738675000",
                          code: "reportPropertyMemberInfo",
                          name: "上报物业成员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportPropertyMemberInfo",
                          url: "reportPropertyMemberInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674005",
                          parentId: "3000598815738675000",
                          code: "reportInformedPerson",
                          name: "上报达人信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportInformedPerson",
                          url: "reportInformedPerson",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674006",
                          parentId: "3000598815738675000",
                          code: "reportOperatorInfo",
                          name: "上报社区运营商",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportOperatorInfo",
                          url: "reportOperatorInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674008",
                          parentId: "3000598815738675000",
                          code: "reportGridInfo",
                          name: "上报网格信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportGridInfo",
                          url: "reportGridInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674011",
                          parentId: "3000598815738675000",
                          code: "reportMiniGrid",
                          name: "上报微网格信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportMiniGrid",
                          url: "reportMiniGrid",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674003",
                          parentId: "3000598815738675000",
                          code: "reportGridPerson",
                          name: "上报网格成员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportGridPerson",
                          url: "reportGridPerson",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },

                        {
                          id: "3000598815738674009",
                          parentId: "3000598815738675000",
                          code: "reportCommunityWorkPerson",
                          name: "上报社区工作人员",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportCommunityWorkPerson",
                          url: "reportCommunityWorkPerson",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674010",
                          parentId: "3000598815738675000",
                          code: "reportAssociationInfo",
                          name: "上报社群信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportAssociationInfo",
                          url: "reportAssociationInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },

                        {
                          id: "3000598815738674012",
                          parentId: "3000598815738675000",
                          code: "reportOwnersCommitteeInfo",
                          name: "上报业委会信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/reportOwnersCommitteeInfo",
                          url: "reportOwnersCommitteeInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738674013",
                          parentId: "3000598815738675000",
                          code: "ownersCommitteeMemberInfo",
                          name: "上报业委会成员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataCommunity/ownersCommitteeMemberInfo",
                          url: "ownersCommitteeMemberInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                      ],
                    },
                    {
                      id: "3000598815738675001",
                      parentId: "3123598815738675343",
                      code: "baseDataPublic",
                      name: "公共空间与设施",
                      alias: "menu",
                      path: "",
                      url: "",
                      source: "iconfont iconicon_sms",
                      sort: 1,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      remark: "",
                      isDeleted: 0,
                      tenantId: "000000",
                      currentPath: "",
                      redirect: "",
                      hidden: false,
                      domain: 0,
                      modelKey: "",
                      parentName: "",
                      categoryName: "",
                      actionName: "",
                      isOpenName: "",
                      hasChildren: false,
                      children: [
                        {
                          id: "3000598815738555001",
                          parentId: "3000598815738675001",
                          code: "shopInfo",
                          name: "上报商家店铺",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataPublic/shopInfo",
                          url: "shopInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738555004",
                          parentId: "3000598815738675001",
                          code: "personTraffic",
                          name: "上报人流量数据",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataPublic/personTraffic",
                          url: "personTraffic",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738555002",
                          parentId: "3000598815738675001",
                          code: "resourceInfo",
                          name: "上报设备资源",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataPublic/resourceInfo",
                          url: "resourceInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                      ],
                    },
                    {
                      id: "3000598815738675002",
                      parentId: "3123598815738675343",
                      code: "baseDataBusiness",
                      name: "业务主题库",
                      alias: "menu",
                      path: "",
                      url: "",
                      source: "iconfont iconicon_sms",
                      sort: 1,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      remark: "",
                      isDeleted: 0,
                      tenantId: "P1C2A2S1D1",
                      currentPath: "",
                      redirect: "",
                      hidden: false,
                      domain: 0,
                      modelKey: "",
                      parentName: "",
                      categoryName: "",
                      actionName: "",
                      isOpenName: "",
                      hasChildren: false,
                      children: [
                        {
                          id: "3000598815738645002",
                          parentId: "3000598815738675002",
                          code: "propertyCharge",
                          name: "上报物业收费信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/propertyCharge",
                          url: "propertyCharge",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645003",
                          parentId: "3000598815738675002",
                          code: "communityEvent",
                          name: "上报社区事件信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityEvent",
                          url: "communityEvent",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645004",
                          parentId: "3000598815738675002",
                          code: "communityActivity",
                          name: "上报社区活动",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityActivity",
                          url: "communityActivity",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645005",
                          parentId: "3000598815738675002",
                          code: "communityVote",
                          name: "上报社区投票信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityVote",
                          url: "communityVote",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645007",
                          parentId: "3000598815738675002",
                          code: "communityAppInfo",
                          name: "上报应用信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityAppInfo",
                          url: "communityAppInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645008",
                          parentId: "3000598815738675002",
                          code: "communityDayActiveInfo",
                          name: "上报应用日活信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityDayActiveInfo",
                          url: "communityDayActiveInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645009",
                          parentId: "3000598815738675002",
                          code: "communityIntegratedInfo",
                          name: "上报应用集成信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityIntegratedInfo",
                          url: "communityIntegratedInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645010",
                          parentId: "3000598815738675002",
                          code: "communityUserInfo",
                          name: "上报社区用户信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityUserInfo",
                          url: "communityUserInfo",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645006",
                          parentId: "3000598815738675002",
                          code: "communityWork",
                          name: "上报社区工作信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityWork",
                          url: "communityWork",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },

                        {
                          id: "3000598815738645011",
                          parentId: "3000598815738675002",
                          code: "communityPlatformIntegral",
                          name: "上报平台积分信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/communityPlatformIntegral",
                          url: "communityPlatformIntegral",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3000598815738645019",
                          parentId: "3000598815738675002",
                          code: "ownersCommitteeMeeting",
                          name: "上报业委会会议信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/baseDataBusiness/ownersCommitteeMeeting",
                          url: "ownersCommitteeMeeting",
                          source: "iconfont iconicon_sms",
                          sort: 1,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "3123598815738675344",
                  parentId: "3123598815738675342",
                  code: "operateDataReport",
                  name: "运营数据上报",
                  alias: "menu",
                  path: "",
                  url: "",
                  source: "iconfont iconicon_sms",
                  sort: 2,
                  category: 1,
                  action: 0,
                  isOpen: 1,
                  remark: "",
                  isDeleted: 0,
                  tenantId: "P1C2A2S1D1",
                  currentPath: "",
                  redirect: "",
                  hidden: false,
                  domain: 0,
                  modelKey: "",
                  parentName: "",
                  categoryName: "",
                  actionName: "",
                  isOpenName: "",
                  hasChildren: false,
                  children: [
                    {
                      id: "3123598815738675300",
                      parentId: "3123598815738675344",
                      code: "operateData",
                      name: "运营数据",
                      alias: "menu",
                      path: "",
                      url: "",
                      source: "iconfont iconicon_sms",
                      sort: 2,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      remark: "",
                      isDeleted: 0,
                      tenantId: "P1C2A2S1D1",
                      currentPath: "",
                      redirect: "",
                      hidden: false,
                      domain: 0,
                      modelKey: "",
                      parentName: "",
                      categoryName: "",
                      actionName: "",
                      isOpenName: "",
                      hasChildren: false,
                      children: [
                        {
                          id: "3123598815738675310",
                          parentId: "3123598815738675300",
                          code: "communityGenera",
                          name: "社区总体情况",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/communityGeneral",
                          url: "communityGenera",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675302",
                          parentId: "3123598815738675300",
                          code: "personnelInfo",
                          name: "基础人员信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/personnelInfo",
                          url: "personnelInfo",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675303",
                          parentId: "3123598815738675300",
                          code: "premises",
                          name: "基础房地产信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/premises",
                          url: "premises",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675304",
                          parentId: "3123598815738675300",
                          code: "suppliesBase",
                          name: "物资信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/suppliesBase",
                          url: "suppliesBase",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675305",
                          parentId: "3123598815738675300",
                          code: "partyOrg",
                          name: "党建信息",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/partyOrg",
                          url: "partyOrg",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675306",
                          parentId: "3123598815738675300",
                          code: "appBase",
                          name: "高频高质应用统计",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/appBase",
                          url: "appBase",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675307",
                          parentId: "3123598815738675300",
                          code: "appFrequency",
                          name: "具体应用",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/appFrequency",
                          url: "appFrequency",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738672307",
                          parentId: "3123598815738675300",
                          code: "provincialStandardApp",
                          name: "省级标配应用",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/provincialStandardApp",
                          url: "provincialStandardApp",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "0000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675308",
                          parentId: "3123598815738675300",
                          code: "govern",
                          name: "治理机制数据",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/govern",
                          url: "govern",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                        {
                          id: "3123598815738675309",
                          parentId: "3123598815738675300",
                          code: "sensoryEvaluation",
                          name: "感受评价数据",
                          alias: "menu",
                          path: "provincePlatformDataUpReport/operateData/sensoryEvaluation",
                          url: "sensoryEvaluation",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                        },
                      ],
                    },
                    {
                      id: "3123598815738675301",
                      parentId: "3123598815738675344",
                      code: "workOrderType",
                      name: "九大场景",
                      alias: "menu",
                      path: "provincePlatformDataUpReport/futureService/workOrderType",
                      url: "workOrderType",
                      source: "iconfont iconicon_sms",
                      sort: 2,
                      category: 1,
                      action: 0,
                      isOpen: 1,
                      remark: "",
                      isDeleted: 0,
                      tenantId: "P1C2A2S1D1",
                      currentPath: "",
                      redirect: "",
                      hidden: false,
                      domain: 0,
                      modelKey: "",
                      parentName: "",
                      categoryName: "",
                      actionName: "",
                      isOpenName: "",
                      hasChildren: false,
                      children: [
                        {
                          id: "3123598815738674000",
                          parentId: "3123598815738675301",
                          code: "futureNeighbor",
                          name: "未来邻里",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815738673001",
                              parentId: "3123598815738674000",
                              code: "convention",
                              name: "公约数据",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureNeighbor/convention",
                              url: "convention",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738673002",
                              parentId: "3123598815738674000",
                              code: "creditsStatistics",
                              name: "积分统计",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureNeighbor/creditsStatistics",
                              url: "creditsStatistics",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "0000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738673003",
                              parentId: "3123598815738674000",
                              code: "creditsExchange",
                              name: "积分兑换",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureNeighbor/creditsExchange",
                              url: "creditsExchange",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "0000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738673004",
                              parentId: "3123598815738674000",
                              code: "spaceData",
                              name: "空间数据",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureNeighbor/spaceData",
                              url: "spaceData",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "0000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674001",
                          parentId: "3123598815738675301",
                          code: "futureEducation",
                          name: "未来教育",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815738672000",
                              parentId: "3123598815738674001",
                              code: "communitySchool",
                              name: "社区学校招生",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/communitySchool",
                              url: "communitySchool",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "00000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738672001",
                              parentId: "3123598815738674001",
                              code: "educatedStatistics",
                              name: "社区受教育统计",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/educatedStatistics",
                              url: "educatedStatistics",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738672002",
                              parentId: "3123598815738674001",
                              code: "facilities",
                              name: "教育配套",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/facilities",
                              url: "facilities",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738672003",
                              parentId: "3123598815738674001",
                              code: "shareStudy",
                              name: "共享书房",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/shareStudy",
                              url: "shareStudy",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "P1C2A2S1D1",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738672004",
                              parentId: "3123598815738674001",
                              code: "happySchool",
                              name: "幸福学堂",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/happySchool",
                              url: "happySchool",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "P1C2A2S1D1",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738672005",
                              parentId: "3123598815738674001",
                              code: "skillTrain",
                              name: "技能培训",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureEducation/skillTrain",
                              url: "skillTrain",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "P1C2A2S1D1",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674002",
                          parentId: "3123598815738675301",
                          code: "futureHealth",
                          name: "未来健康",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "000000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815738664000",
                              parentId: "3123598815738674002",
                              code: "healthStatistics",
                              name: "健康统计",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureHealth/healthStatistics",
                              url: "healthStatistics",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "0000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738664001",
                              parentId: "3123598815738674002",
                              code: "serviceOldAged",
                              name: "养老服务",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureHealth/serviceOldAged",
                              url: "serviceOldAged",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815738664002",
                              parentId: "3123598815738674002",
                              code: "healthFacilities",
                              name: "健康配套",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureHealth/healthFacilities",
                              url: "healthFacilities",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674003",
                          parentId: "3123598815738675301",
                          code: "scenario",
                          name: "未来创业",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815738614003",
                              parentId: "3123598815738674003",
                              code: "scenario",
                              name: "未来创业场景",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureCreative/scenario",
                              url: "scenario",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "P1C2A2S1D1",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674004",
                          parentId: "3123598815738675301",
                          code: "futureArchitecture",
                          name: "未来建筑",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "00000",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815138674004",
                              parentId: "3123598815738674004",
                              code: "architectureScenario",
                              name: "未来建筑场景",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureArchitecture/architectureScenario",
                              url: "architectureScenario",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815138674005",
                              parentId: "3123598815738674004",
                              code: "architectureCase",
                              name: "建筑案例",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureArchitecture/architectureCase",
                              url: "architectureCase",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674005",
                          parentId: "3123598815738675301",
                          code: "futureTraffic",
                          name: "未来交通",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "2123598815738674005",
                              parentId: "3123598815738674005",
                              code: "trafficScenario",
                              name: "未来交通场景",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureTraffic/trafficScenario",
                              url: "trafficScenario",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "2123598815738674006",
                              parentId: "3123598815738674005",
                              code: "trafficLogistics",
                              name: "每日交通物流",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureTraffic/trafficLogistics",
                              url: "trafficLogistics",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "2123598815738674007",
                              parentId: "3123598815738674005",
                              code: "trafficData",
                              name: "每日人员通行数据",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureTraffic/trafficData",
                              url: "trafficData",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674006",
                          parentId: "3123598815738675301",
                          code: "futureLowCarbon",
                          name: "未来低碳",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3113598815738674000",
                              parentId: "3123598815738674006",
                              code: "lowCarbonScenario",
                              name: "未来低碳场景",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureLowCarbon/lowCarbonScenario",
                              url: "lowCarbonScenario",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "0000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3113598815738674001",
                              parentId: "3123598815738674006",
                              code: "lowCarbonResource",
                              name: "资源循环利用",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureLowCarbon/lowCarbonResource",
                              url: "lowCarbonResource",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674007",
                          parentId: "3123598815738675301",
                          code: "futureService",
                          name: "未来服务",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598811138674007",
                              parentId: "3123598815738674007",
                              code: "serviceScenario",
                              name: "未来服务场景",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureService/serviceScenario",
                              url: "serviceScenario",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598811138674008",
                              parentId: "3123598815738674007",
                              code: "serviceIncrement",
                              name: "增值服务",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureService/serviceIncrement",
                              url: "serviceIncrement",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                        {
                          id: "3123598815738674008",
                          parentId: "3123598815738675301",
                          code: "futureGovern",
                          name: "未来治理",
                          alias: "menu",
                          path: "",
                          url: "",
                          source: "iconfont iconicon_sms",
                          sort: 2,
                          category: 1,
                          action: 0,
                          isOpen: 1,
                          remark: "",
                          isDeleted: 0,
                          tenantId: "P1C2A2S1D1",
                          currentPath: "",
                          redirect: "",
                          hidden: false,
                          domain: 0,
                          modelKey: "",
                          parentName: "",
                          categoryName: "",
                          actionName: "",
                          isOpenName: "",
                          hasChildren: false,
                          children: [
                            {
                              id: "3123598815721674008",
                              parentId: "3123598815738674008",
                              code: "eventsData",
                              name: "事件数据",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureGovern/eventsData",
                              url: "eventsData",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815721674009",
                              parentId: "3123598815738674008",
                              code: "eventsStatistics",
                              name: "事件分类统计",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureGovern/eventsStatistics",
                              url: "eventsStatistics",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                            {
                              id: "3123598815721674011",
                              parentId: "3123598815738674008",
                              code: "ownersCommittee",
                              name: "居民业务会",
                              alias: "menu",
                              path: "provincePlatformDataUpReport/futureGovern/ownersCommittee",
                              url: "ownersCommittee",
                              source: "iconfont iconicon_sms",
                              sort: 2,
                              category: 1,
                              action: 0,
                              isOpen: 1,
                              remark: "",
                              isDeleted: 0,
                              tenantId: "000000",
                              currentPath: "",
                              redirect: "",
                              hidden: false,
                              domain: 0,
                              modelKey: "",
                              parentName: "",
                              categoryName: "",
                              actionName: "",
                              isOpenName: "",
                              hasChildren: false,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ];
            }
          });
          this.oList = res.data;
          this.$store.commit("app/setMenu", this.oList);
          const formatMenu = this.addField(this.menuList);
          const flatMenu = this.getFlatArr(formatMenu);
          console.log(formatMenu, flatMenu, "======");
          this.$store.commit("app/setFormatMenu", formatMenu);
          this.$store.commit("app/setFlatMenu", flatMenu);
          if (this.$route.meta.fromId == "第三方") {
            this.handleTeRouter();
          } else {
            this.handleRouter();
          }
          if (this.$route.meta.fromId == "第三方") {
            this.getTeBreadcrumb();
          } else {
            this.getBreadcrumb();
          }
        } else {
        }
      });
    },
    // 为数组添加字段
    addField(a, o = []) {
      let deepArr = _.cloneDeep(a);
      deepArr.forEach((route) => {
        // 1. 用于追溯该对象所有的上层对象
        route.parentArr = o;
        // 2. 用于判断该节点是否需要变成被选中状态
        route.isSelect = false;
        // 3. 递归设置
        if (route.children) {
          route.children = this.addField(route.children, [route, ...o]);
        }
      });
      return deepArr;
    },
    // 获取扁平化数组
    getFlatArr(a) {
      let deepArr = _.cloneDeep(a);
      let resultArr = [];
      let flatMethos = (childs) => {
        childs.forEach((child) => {
          resultArr.push(child);
          if (child.children) {
            flatMethos(child.children);
          }
        });
      };
      flatMethos(deepArr);
      return resultArr;
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/less/global.less";
.hc-sideBar {
  width: 100%;
  height: 100%;
  // overflow: auto;
  // overflow-x: hidden;
  padding: 10px 0px 10px 12px;
  box-sizing: border-box;
  background: #fff;
  .scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      margin: 0 !important;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px; /* 纵向滚动条*/
        height: 0px; /* 横向滚动条 */
        background-color: #fff;
      }

      /*定义滚动条轨道 内阴影*/
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: #ccc;
      }

      /*定义滑块 内阴影*/
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
      }
    }
  }
  ::v-deep .el-menu {
    border: 0;
    width: 100%;
  }
}
</style>
